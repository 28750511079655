@import '../../styles/variaveis.scss';

.logoSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto; // Posiciona à esquerda
  margin-left: 15%;
  padding-left: 2rem; // Ajuste para dar espaço entre a borda e a imagem

  img {
    max-width: 100%; // Deixa a imagem responsiva
    max-height: 580px; // Aumenta o tamanho da imagem

    @media (max-width: 950px) {
      display: none; // Esconde a imagem no mobile
    }
  }
}

.mainSectionLogin {
  background: linear-gradient(
    to bottom,
    $primary 0%,
    $fourty 50%,
    $fourty 90%
  );
  background-size: cover;
  height: 100vh;
  padding-top: 4%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 950px) {
    margin-left: -120px !important;
  }
}

.containerLogin {
  background-color: #FAFEFD !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7); 
  font-family: 'PlusJakartaSans-Bold';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 642px;
  height: 800px;
  margin-right: 5%; // Ajusta o container à direita
  border-radius: 20px;
  position: relative; // Para o efeito de degradação
  overflow: hidden; // Para o efeito de degradação
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (max-width: 950px) {
    
    height: auto; // Deixe a altura automática no mobile
    margin-right: 0 !important; // Centraliza o container no mobile
    padding-left: 6rem !important;
    padding-right: 5rem !important;
  }
}

img {
  max-width: 80%;
  max-height: 100px;
  margin-bottom: 8rem;
}

.inputWithIcon {
  width: 80%;
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;

  .inputGroup {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;

    .form-control {
      border: 1px solid $primary;
      border-radius: 5px;
    }
  }
}

.customCheckboxLabel {
  color: $primary;
  font-size: 14px;
  margin-top: 1rem;
}

.form-control {
  height: calc(1.5em + .75rem + 2px); /* Ajusta a altura do input */
}
