@import '../../../styles/variaveis.scss';

.sideBarOpen {
  transition: all .5s;
  gap: 10rem;
  margin-left: 15rem;
}

.open {
  max-width: 40rem;
}

.close {
  max-width: 50rem;
}

.sideBarClose {
  max-width: 80rem;
  transition: all 5s;
  margin-left: 5rem !important;
}

.containerMain {
  font-family: 'PlusJakartaSans-Light' !important;
}

/* Para telas muito grandes, TVs (1201px e acima) */
@media (min-width: 1201px) {
  .containerMain {
    margin-left: 24% !important; /* Ajuste conforme necessário */
  }
}

/* Para desktops, telas grandes (1025px — 1200px) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .containerMain {
    margin-left: 26% !important; /* Ajuste conforme necessário */
    width: 73%;
    font-size: 0.8rem;
  }
}

/* Para telas pequenas, laptops (769px — 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .containerMain {
    margin-left: 31% !important;
    width: 69%;
    font-size: 0.8rem;
  }
}

/* Para iPads, tablets (481px — 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .containerMain {
    margin-left: 2rem !important; /* Ajuste conforme necessário */
  }
  .spanCard{
    font-size: 0.9rem !important;
  }
  .numberSmall {
    font-size: 0.9rem !important;
  }
  
}

/* Para dispositivos móveis (320px — 480px) */
@media (max-width: 480px) {
  .containerMain {
    margin-left: 0 !important; /* Sem margin-left em dispositivos móveis */
  }
  .spanCard{
    font-size: 0.8rem !important;
  }
  .numberSmall {
    font-size: 0.8rem !important;
  }
}


// Estilizacao para o header inicial

.cardProduction {
  background-color: $fourty;
  color: white;
  cursor: pointer !important;
}
.spanCard{
  font-size: 1.3rem;
}

.iconSmall {
  font-size: 1.2rem;
}

.numberSmall {
  font-size: 1.4rem;
}

.cardProjection {
  background-color: $fourty;
  color: white;
  border: none;
}

// Estilização para os itens não-ativos
.listGroupItem {
  color: black; // Cor do texto para os itens não-ativos
  transition: background-color 0.3s ease; // Transição suave para a cor de fundo ao passar o mouse
  background-color: none;

  &:hover {
    background-color: $fourty !important; // Cor de fundo ao passar o mouse
    color: white !important; // Cor do texto ao passar o mouse
  }
}

.iconSpacing {
  margin-right: 0.7rem;
}


.active {
  background-color: $fourty !important;
  color: white !important;
}