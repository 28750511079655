
$aquaBlue : #52bfcc; //azul fraco
$navyBlue : #1362a7; //azul primário
$midnightBlue : #014872; //azul mais escuro 
$royalBlue : #154798; //azul secundário
$darkSlateBlue : #1d2345; //azul secundário
$white: #fff;

$primary:#1A23E6;
$secondary: #0F6DFC;
$third: #8EDAF5;
$fourty: #062173;
$fivety: #6D8EF2;


$sideBarGradient: linear-gradient(to bottom, $primary 10%, $fourty 80%);


//Shadow
$shadowPrimary: #101010;
$shadowSecondary: #0F0F0F;
$shadowTertiary: #505050;


//Light
$lightPrimary: #D9D9D9;
$lightSecondary: #F2F2F2;


//Fontes

@font-face {
    font-family: 'PlusJakartaSans-Light';
    src: url(../assets/fonts/PlusJakartaSans-Light.ttf);
}

@font-face {
    font-family: 'PlusJakartaSans-Bold';
    src: url(../assets/fonts/PlusJakartaSans-Bold.ttf);
}

@font-face {
    font-family: 'PlusJakartaSans-Medium';
    src: url(../assets/fonts/PlusJakartaSans-Medium.ttf);
}
