@import '../../styles/variaveis.scss';

.ButtonLogin{
    font-family: 'PlusJakartaSans-Light';
    background: linear-gradient(
        to bottom,
        $secondary 0%,
        #021E78 50%
    );
    padding-top: 0.5rem;
    margin-top: 10%;
    padding-bottom: 0.5rem;
    padding-left: 12rem;
    padding-right: 12rem;
    transition: .5s all;
    &:hover{
        transition: .5s all;
        transform: scale(1.1);
    }
    @media (max-width: 950px) {
        .ButtonLogin{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}