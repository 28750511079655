$primary-color: #062173;
$bar-color: #062173;
$bg-color: #f8f9fa;
$font-color: #333;

.container {
  background-color: $bg-color;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  h3 {
    color: $primary-color;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  form {
    .form-label {
      font-size: 1.1rem;
      color: $font-color;
    }

    .form-select {
      border-color: $primary-color;
      &:focus {
        border-color: darken($primary-color, 10%);
        box-shadow: 0 0 5px rgba($primary-color, 0.5);
      }
    }

    .row {
      align-items: center;

      .btn-danger {
        font-size: 0.9rem;
        padding: 8px 12px;
        background-color: #d9534f;
        border-color: #d9534f;
        &:hover {
          background-color: darken(#d9534f, 10%);
        }
      }
    }

    .btn-primary {
      margin-top: 10px;
      font-size: 1rem;
      background-color: $primary-color;
      border-color: $primary-color;
      &:hover {
        background-color: darken($primary-color, 10%);
        border-color: darken($primary-color, 10%);
      }
    }
  }
}

.chart-container {
  margin-top: 30px;

  .recharts-wrapper {
    .recharts-bar {
      rect {
        fill: $bar-color !important;
      }
    }

    .recharts-tooltip-wrapper {
      font-size: 0.9rem;
      background: rgba(255, 255, 255, 0.95);
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    .recharts-label {
      fill: #fff;
      font-weight: bold;
    }
  }
}
