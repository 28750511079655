@import '../../../styles/variaveis.scss';

.sideBar {
  display: flex;
  font-family: 'PlusJakartaSans-Light';
  flex-direction: column;
  background: $sideBarGradient;
  width: 320px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  color: $fivety;
  transition: transform 0.3s ease, width 0.3s ease; /* Added width transition */
  z-index: 1000;

  @media (max-width: 1200px) {
    width: 25%; /* Ajuste conforme necessário */
  }

  @media (max-width: 1024px) {
    width: 30%; /* Ajuste conforme necessário */
  }

  @media (max-width: 800px) {
    width: 100%; /* Full width on mobile */
  }
}

.closed {
  transform: translateX(-100%);
}

.open {
  transform: translateX(0);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;

  .bars {
    display: block; /* Always show the bars icon */
    margin-bottom: 8rem;
    color: $fivety;

    @media (min-width: 801px) {
      display: none; /* Hide on larger screens */
    }
  }

  img {
    width: 100px;
    height: 95.58px;
    margin-top: 48px;
    margin-left: 70px;
    gap: 0px;
    opacity: 100 !important;
  }
}

.bars {
  cursor: pointer;
  font-size: 2rem;
  color: $primary;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 3rem;
}

.navItem {
  width: 100%; /* Full width */
  height: auto; /* Auto height */
  gap: 20px;
  opacity: 100 !important;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  color: white;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    transform: scale(1.1);
    transition: 0.3s;
  }
}

.navIcon {
  margin-right: .5rem;
  margin-left: 1rem;
  font-size: 1.5rem;
  color: white;
}

.textItem {
  font-size: 20px;
  font-weight: 700;
  line-height: 25.2px;
  text-align: left;
}

.toggleMenu {
  position: fixed;
  top: .5rem;
  left: 1.5rem;
  padding: 1rem;
  border-radius: 15px;
  cursor: pointer;
  z-index: 1100;
  display: block; /* Always show the toggle menu on mobile */

  @media (min-width: 801px) {
    display: none; /* Hide on larger screens */
  }
}

.topBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    transition: left 0.3s ease, width 0.3s ease;
    z-index: 1001;
  
    @media (max-width: 800px) {
      display: none; /* This hides the top bar on screens smaller than 800px */
    }
  
    // To override the hiding on smaller screens when the sidebar is closed
    &.menuClosed {
      display: flex !important; /* Forces the top bar to display when the sidebar is closed */
      width: 100%; /* Full width when the sidebar is closed */
      left: 0; /* Align to the left edge */
    }
  }
  
  .topBar.menuOpen {
    left: 320px; /* Adjust this according to the sidebar width */
    width: calc(100% - 320px); /* Adjust according to sidebar width */
    transition: left 0.3s ease, width 0.3s ease;
  }
  

.topBarIconsContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 3rem;
  margin-right: 1rem;
}

.topBarIcons {
  color: $primary;
  font-size: 1.7rem;
  transition: .5s;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    transition: .5s;
  }
}

.dropdownIcon {
  color: $primary;
  font-size: 1.25rem;
}

.dropdown {
  border-radius: 15px;
  color: $secondary;
  font-size: 17px;
  transition: .5s;
  padding: 20px;
  font-family: 'PlusJakartaSans-Light';

  .dropdownItem:hover {
    background-color: $fivety;
    transition: .5s;
    border-radius: .5rem;
  }
}

.btnClose:hover {
  transform: scale(1.2);
}

.btnLogout {
  background-color: $primary;
  border: 1px solid $primary;

  &:hover {
    background-color: $primary;
    border: 1px solid $primary;
    transform: scale(1.2);
  }
}

.logoutTitle {
  font-family: 'PlusJakartaSans-Bold';
  color: $primary;
}

/* Ajustes para .containerMain conforme tamanho da tela */
@media (min-width: 1201px) {
  .containerMain {
    margin-left: 24% !important; /* Ajuste conforme necessário */
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .containerMain {
    margin-left: 32% !important; /* Ajuste conforme necessário */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .containerMain {
    margin-left: 37% !important; /* Ajuste conforme necessário */
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .containerMain {
    margin-left: 4rem !important; /* Ajuste conforme necessário */
  }
}

@media (max-width: 480px) {
  .containerMain {
    margin-left: 0 !important; /* Sem margin-left em dispositivos móveis */
  }
}
